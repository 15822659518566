.features_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, #f7f7fe 50%, #fff 50%);
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 30px;
  }
  a {
    min-width: 170px;
    min-height: 172px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: #ffffff;
    border-radius: 10px;
    transition: all 0.3s;
    gap: 16px;
    border: 1px solid #f7f7f7;
    padding-top: 30px;
    &:hover {
      box-shadow: 0px 6px 14px rgba(150, 185, 255, 0.25);
    }
    .content {
      max-width: 90px;
      text-align: center;
    }
  }
}

@media (max-width: 1200px) {
  .features_wrapper {
    left: 0;
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
    }
  }
}

@media (max-width: 768px) {
  .features_wrapper {
    ul {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
  }
}

@media (max-width: 410px) {
  .features_wrapper ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    a {
      min-width: 150px;
      min-height: 160px;
    }
  }
}
