.search_course_skeleton {
  height: 70px;
}

.section_banner_two {
  background-color: #f7f7fe;
  padding-top: 70px;
  position: relative;
  padding-bottom: 0px;
}

.banner_content_wrapper {
  margin-top: 80px;
  position: relative;
  .title_wrapper {
    max-width: 545px;
    .title {
      margin-bottom: 20px;
    }
  }
}

.advanced_search_wrapper {
  width: 800px;
  margin-top: 20px;
  margin-bottom: 20px;
  top: calc(100% + 18px);
  left: 0px;
  position: relative;
}

.search_fields_wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr max-content max-content;
  gap: 15px;
  max-height: 50px;

  .btn_search {
    border-radius: 5px;
  }

  .search_field {
    background: #f7f7fe;
    border-radius: 5px;
    padding: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border: none;
    &::placeholder {
      color: #bdbdbd;
    }
  }

  .btn_advanced_option {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0f1041;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    border: none;
    transition: all 0.3s;
    &:hover {
      background-color: #f47f20;
    }
  }
}

@media (max-width: 900px) {
  .section_banner_two {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .banner_content_wrapper {
    margin-top: 0px;
    .title_wrapper {
      max-width: unset;
      .title {
        margin-bottom: 20px;
      }
    }
  }
  .advanced_search_wrapper {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .banner_two {
    display: block !important;
  }
  .search_course_skeleton {
    height: 300px;
  }
  .banner_caresoul {
    display: block !important;
  }
}
